<template>
    <div class="register-wrapper container">
        <div class="register_card row">
            <div class="col text-center">
                <h1 class="register_title">Get started now</h1>
                <div class="row">
                    <div class="col">
                        <form @submit.prevent="register" name="yodalRegisterWidgetForm" id="yodalRegisterWidgetForm">
                            <div class="form-group row">
                                <label for="first_name" class="col-sm-3 text-left my-auto"
                                    >First Name</label
                                >
                                <input
                                    class="col-sm-9 form-control yodal-rebrandable yodal-register-rebrandable yodal-dont-hide border-color-focus-primary"
                                    type="text"
                                    id="first_name"
                                    name="first_name"
                                    placeholder="Your first name"
                                    v-model="first_name"
                                    required
                                />
                            </div>
                            <div class="form-group row">
                                <label for="last_name" class="col-sm-3 text-left my-auto"
                                    >Last Name</label
                                >
                                <input
                                    class="col-sm-9 form-control yodal-rebrandable yodal-register-rebrandable yodal-dont-hide border-color-focus-primary"
                                    type="text"
                                    id="last_name"
                                    name="last_name"
                                    placeholder="Your last name"
                                    v-model="last_name"
                                    required
                                />
                            </div>
                            <div class="form-group row">
                                <label for="password" class="col-sm-3 text-left my-auto"
                                    >Password</label
                                >
                                <input
                                    class="col-sm-9 form-control yodal-rebrandable yodal-register-rebrandable yodal-dont-hide border-color-focus-primary"
                                    type="password"
                                    id="password"
                                    name="password"
                                    placeholder="Password (minimum 8 characters)"
                                    required
                                    title="Password must be at least 8 characters long"
                                    pattern=".{8,}"
                                    v-model="password"
                                />
                            </div>
                            <div class="form-group row">
                                <label for="password2" class="col-sm-3 text-left my-auto"
                                    >Confirm Password</label
                                >
                                <input
                                    class="col-sm-9 form-control yodal-rebrandable yodal-register-rebrandable yodal-dont-hide border-color-focus-primary"
                                    type="password"
                                    id="password2"
                                    name="password2"
                                    placeholder="Password confirmation"
                                    required
                                    title="Password must be at least 8 characters long"
                                    pattern=".{8,}"
                                    v-model="password2"
                                />
                            </div>
    
                            <div class="form-group row">
                                <label for="phone" class="col-sm-3 text-left my-auto">Phone</label>
                                <input
                                    class="col-sm-9 form-control yodal-rebrandable yodal-register-rebrandable yodal-dont-hide border-color-focus-primary"
                                    type="text"
                                    id="phone"
                                    name="phone"
                                    placeholder="Your phone number"
                                    v-model="phone"
                                    required
                                />
                            </div>
                            <div class="form-group row">
                                <label for="mobile" class="col-sm-3 text-left my-auto">Mobile</label>
                                <input
                                    class="col-sm-9 form-control yodal-rebrandable yodal-register-rebrandable yodal-dont-hide border-color-focus-primary"
                                    type="text"
                                    id="mobile"
                                    name="mobile"
                                    placeholder="Your mobile phone number"
                                    v-model="mobile"
                                />
                            </div>
                            <div class="form-group row">
                                <label for="email" class="col-sm-3 text-left my-auto">Email</label>
                                <input
                                    class="col-sm-9 form-control yodal-rebrandable yodal-register-rebrandable yodal-dont-hide border-color-focus-primary"
                                    type="email"
                                    id="email"
                                    name="email"
                                    placeholder="E-mail"
                                    v-model="email"
                                    required
                                />
                            </div>
                            <div class="form-group row">
                                <label for="email2" class="col-sm-3 text-left my-auto"
                                    >Confirm Email</label
                                >
                                <input
                                    class="col-sm-9 form-control yodal-rebrandable yodal-register-rebrandable yodal-dont-hide border-color-focus-primary"
                                    type="email"
                                    id="email2"
                                    name="confirm_email"
                                    placeholder="E-mail confirmation"
                                    v-model="email2"
                                    required
                                />
                            </div>
                            <div
                                id="yodal-registration-errors"
                                class="alert alert-danger"
                                style="display:none;"
                            ></div>
    
                            <div class="register_links row yodal-hidden-ancestor">
                                <div class="col form-check">
                                    <input
                                        type="checkbox"
                                        class="form-check-input"
                                        name="terms_and_conditions"
                                        id="terms-conditions"
                                        v-model="terms_and_conditions"
                                        required
                                    />
                                    <label class="form-check-label"
                                        >I agree to the
                                        <a
                                            href="https://app.yodal.com.au/yodal_adviser_terms_of_use.pdf"
                                            target="_blank"
                                            class="yodal-rebrandable yodal-register-rebrandable color-primary"
                                            >Terms and Conditions</a
                                        >
                                    </label>
                                </div>
                            </div>
    
                            <div class="register_buttons row">
                                <div class="col">
                                    <div
                                        class="g-recaptcha"
                                        data-sitekey="6LekYx4UAAAAAJHmTA3_fSyUBfo8s2nAcGXCmgHs"
                                    ></div>
                                    <button
                                        type="submit"
                                        class="yodal-rebrandable yodal-register-rebrandable background-primary"
                                    >
                                        Register Now
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'YodalRegister',

    props: ['account', 'apihost'],

    data: function() {
        return {
            first_name: '',
            last_name: '',
            password: '',
            password2: '',
            phone: '',
            mobile: '',
            email: '',
            email2: '',
            terms_and_conditions: '',
        }
    },

    methods: {
        boot: function() {
            // const accountSettingsPromise = this.getAccountSettings()
            this.getAccountSettings().then(
                (settings) => {
                    this.applyBranding(settings)
                },
                // Below: solution for rebrandable elements remaining hidden upon failure to get settings.
                // Passing error anonymously: yarn throws an error if we define an arg but don't use it. we don't use it.
                () => {
                    this.applyBranding()
                }
            )
        },
        getApiRoute: function() {
            return this.apihost || 'https://app.yodal.com.au'
        },
        getAccountSettings: function() {
            var url = `${this.getApiRoute()}/api/widgets/account_settings/${this.account}`
            const response = fetch(url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            })
            return response.then((data) => {
                return data.json()
            })
        },
        applyBranding: function(settings) {
            const defaultConfig = {
                primary_colour: '#00adbc',
            }
            // optional chaining ftw!!!
            let rebrandConfig = settings?.account_settings?.feature_config?.rebrand || defaultConfig

            const rebrandables = this.$el.getElementsByClassName('yodal-register-rebrandable')
            for (let el of rebrandables) {
                if (el.className.includes('background-primary')) {
                    el.style.backgroundColor = rebrandConfig.primary_colour
                }
                if (el.className.includes('color-primary')) {
                    el.style.color = rebrandConfig.primary_colour
                }
                // override bootstrap's form input styling
                if (el.className.includes('border-color-focus-primary')) {
                    el.addEventListener('focus', function() {
                        el.style.borderColor = rebrandConfig.primary_colour
                    })
                    // apparently blur is the opposite of focus, so here we remove focus styling for form input fields after leaving focus
                    el.addEventListener('blur', function() {
                        el.style.borderColor = ''
                    })
                }
                // rebrandables are 'hidden' by default, to prevent janky color switches from default to custom.
                // having applied correct or default colouring in either case, we now show them.
                el.style.opacity = 1
            }
            this.showHiddenAncestorElements()
        },
        showHiddenAncestorElements: function() {
            // To further improve UX, some elements which are ancestors to rebrandable elements should be hidden until branding is resolved,
            // e.g., to avoid seeing "[ ] I agree to the ..." before rebrandable "terms and conditions" link is shown.
            // This function reveals those ancestor elements, and should be called upon resolution of branding.
            const hiddenAncestors = this.$el.getElementsByClassName('yodal-hidden-ancestor')
            for (let el of hiddenAncestors) {
                el.style.opacity = 1
            }
        },

        register: function() {
            this.resetRegistrationErrors()
            let form = this.getYodalRegistrationForm()
            if (form.email.value != form.email2.value) {
                this.showRegistrationErrors('Email addresses do not match')
                return false
            }

            form.reportValidity()

            let request_body = {
                first_name: form.first_name.value,
                last_name: form.last_name.value,
                email: form.email.value,
                password: form.password.value,
                mobile: form.mobile.value,
                phone: form.phone.value,
                account_id: this.getAccountId(),
            }
            var result = this.yodalPostRegistration(request_body)

            result.then((data) => {
                if (data.redirect_url) {
                    window.location.href = data.redirect_url
                } else {
                    this.showRegistrationErrors(data.error)
                }
            })
        },
        yodalPostRegistration: async function(request_body) {
            let url = this.getApiRoute() + '/api/widgets/register'
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(request_body),
            })
            return await response.json()
        },
        getYodalRegistrationForm: function() {
            return this.$el.querySelector('#yodalRegisterWidgetForm')
        },
        getRegistrationParentElement: function() {
            return document.getElementById('yodal-register')
        },
        getAccountId: function() {
            return this.getRegistrationParentElement()?.getAttribute('data-account-id') || this.account
        },
        showRegistrationErrors: function(error) {
            let errorElement = this.$el.querySelector('#yodal-registration-errors')
            errorElement.innerHTML = error
            errorElement.style.display = ''
        },
        resetRegistrationErrors: function() {
            let errorElement = this.$el.querySelector('#yodal-registration-errors')
            errorElement.innerHTML = ''
            errorElement.style.display = 'none'
        },
    },

    created: function() {
        this.boot()
    },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

.register-wrapper {
    &/deep/ {
        @import '~bootstrap/dist/css/bootstrap.min';
        // some bootstrap magic is not applied unless we import its .css explicitly as well for some reason
        @import '~bootstrap/dist/css/bootstrap.min.css';
    }
    max-width: 700px;
    margin: auto;
    .register_links label {
        display: inline;
    }
    font-family: apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
    line-height: 1.5;
}

.register_card {
    padding: 30px;
    background: white;
    border-radius: 4px;     
    h1 {
        margin-bottom: 15px;
    }
    .form-group {
        label {
            padding-left: 0;
        }
        input:focus {
            box-shadow: none;
            border-color: #00adbc;
        }
    }
    .register_links {
        line-height: 1.5;
        padding: 30px;
        a {
            color: #00adbc;
        }
    }
}

.yodal-rebrandable:not(.yodal-dont-hide),
.yodal-hidden-ancestor {
    /* hide rebrandables until we know what branding to apply, unless specified not to */
    opacity: 0;
    transition: opacity 300ms;
}

.register_title {
    font-family: 'blacker-display-bold', 'Lato', sans-serif;
    font-family: 'Metropolis-Regular', 'Lato', sans-serif;
    font-size: 45px;
    font-weight: 300;
    line-height: 1.23em;
    color: #54565b;
    margin: 0;
}

.register_buttons {
    padding-bottom: 15px;
    button {
        padding: 10px 45px;
        background-color: #00adbc;
        color: #fff;
        font-size: 14px;
        border: 0;
        border-radius: 30px;
        line-height: 20px;
        &:hover {
            opacity: 0.7;
            color: #ffffff;
            cursor: pointer !important;
        }
    }
}
</style>
